html {
  font-size: 16px;
  height: 100%;
  scroll-behavior: smooth;
  @include media-breakpoint-up(md) {
    font-size: 18px;
  }
  @include media-breakpoint-up(lg) {
    font-size: 20px;
  }
  @include media-breakpoint-up(xl) {
    font-size: 22px;
  }
  body {
  }
}
img {
  @include img-fluid;
}
.row {
  &.vertical-center {
    align-items: center;
  }
  &.justify-center {
    justify-content: center;
  }
}
a {
  @include transition($link-transition);
  &.readmore {
    font-size: $font-size-lg;
    font-weight: $font-weight-bold;
    &:after {
      @include transition($link-transition-move);
      display: inline-block;
      font-style: normal;
      font-variant: normal;
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
      font: 900 $font-size-sm "Font Awesome 5 Pro";
      content: "\f324";
      line-height: $btn-line-height;
      margin-left: .4em;
    }
    @include hover-focus {
      @include animate-arrow;
      text-decoration: none;
    }
  }
  &.read-more {
    font-size: $font-size-lg;
    font-weight: $font-weight-bold;
    text-decoration: none;
    i {
      @include transition($link-transition-move);
      margin-left: .4em;
      font-size: $font-size-sm;
      color: $brand-secondary;
    }
    @include hover-focus {
      text-decoration: none;
      i {
        transform: translateX($arrow-animate-size);
      }
    }
  }
  &.printlink {
    cursor: pointer;
  }
}
hr {
  border-color: $brand-primary;
}

/* ELEMENTS
  --------------------------------------- */
.container {
  @include media-breakpoint-down(xs) {
    padding-left: $grid-gutter-width;
    padding-right: $grid-gutter-width;
  }
}
.nav-piped {
  @include nav-piped;
}
.wrap {
  main.main {
    &>h1,&>.h1,&>h2,&>.h2,&>h3,&>.h3 {
      color: $brand-primary;
    }
    &>h4,&>.h4 {
      color: $sage;
      margin-bottom: .25em;
    }

    @include scaled-spacer('margin','top','xl');
    @include scaled-spacer('margin','bottom','xl');
  }
}

/* FONTS
  --------------------------------------- */
.overline {
  @include overline;
  @include paragraph-spacing;
}
h1,.h1,h2,.h2,h3,.h3,h4,.h4,h5,.h5,h6,.h6 {
//   @include paragraph-spacing;
}
h5,.h5 {
  @include overline;
}
h6,.h6 {
  font-weight: $font-weight-base;
}
p,ol,ul,blockquote,.wp-block-image {
  @include paragraph-spacing;
}
