.footer-decorative-wrap {
  padding: 50px 0 120px;
  background-position: center bottom 40px;
  @include media-breakpoint-up(md) {
    padding: 60px 0 180px;
    background-position: center bottom 60px;
  }
  @include media-breakpoint-up(lg) {
    padding: 80px 0 220px;
    background-position: center bottom 80px;
  }
  background-image: url(../images/decorative-background-green.png);
  background-repeat: no-repeat;
  background-size: 100% auto;
  .footer-decorative-col {
    @include make-col-ready();
    @include main-column-width;
    @include last-child-zero-margin;
  }
}

.footer-cta-wrap {
  @include scaled-spacer('padding','top','lg');
  @include scaled-spacer('padding','bottom','lg');
  .footer-cta {
    @include make-col-ready();
    @include main-column-width;
    @include last-child-zero-margin;
    font-size: $font-size-sm;
    a:not(.readmore):not(.read-more) {
      color: $brand-secondary;
    }
  }
}
footer.content-info {
  hr {
    @include scaled-spacer('margin','top','md');
    @include scaled-spacer('margin','bottom','md');
  }
  section.footer-branding {
    @include scaled-spacer('padding','top','md');
    @include scaled-spacer('padding','bottom','md');
    @include color-block('gray-light');
    &>.container {
      &>.row {
        @include scaled-spacer('margin','bottom','xs');
        align-items: center;
        @include media-breakpoint-down(sm) {
          text-align: center;
//           flex-direction: column-reverse;
          .nav-footer {
            @include scaled-spacer('margin','bottom','md');
          }
        }
      }
    }
    .footer-badge {
      .badge-wrap {
        align-items: center;
        @include media-breakpoint-down(md) {
          flex-direction: column;
        }
        img {
          max-width: 125px;
          margin: 0 auto;
          @include media-breakpoint-up(md) {
            margin: 0px 15px 0px 0px;
          }
        }
        span {
          margin: 0 auto 15px;
          font-size: $font-size-sm;
          font-weight: $font-weight-bold;
          color: $brand-primary;
          line-height: 1.3;
          @include media-breakpoint-up(md) {
            max-width: 290px;
            text-align: left;
          }
          @include media-breakpoint-up(lg) {
            margin: 0;
            max-width: 320px;
          }
          @include media-breakpoint-up(xl) {
            max-width: 450px;
          }
        }
      }
    }
    .nav-phone {
      @include nav-piped;
      display: block;
      @include media-breakpoint-down(sm) {
        @include scaled-spacer('margin','bottom','md');
      }
      @include media-breakpoint-only(md) {
        font-size: $font-size-sm;
      }
      @include media-breakpoint-up(md) {
        float: left;
        ul {
          justify-content: flex-start;
        }
      }
    }
    .nav-social {
      @include nav-horizontal;
      display: block;
      @include media-breakpoint-down(sm) {
        @include scaled-spacer('margin','bottom','sm');
      }
      ul {
        li {
          padding: 0 .35em;
          @include media-breakpoint-only(md) {
            padding: 0 .2em;
          }
        }
      }
      @include media-breakpoint-up(md) {
        float: right;
        ul {
          justify-content: flex-end;
        }
      }
      @include media-breakpoint-up(lg) {
        margin-right: $grid-gutter-width;
      }
    }
    .nav-cta-primary {
      display: block;
      @include media-breakpoint-up(md) {
        float: right;
        ul {
          justify-content: flex-end;
        }
      }
    }
    .brand {
      display: block;
      width: 100%;
      max-width: 284px;
      @include scaled-spacer('margin','bottom','md');
      @include media-breakpoint-down(sm) {
        margin-left: auto;
        margin-right: auto;
      }
    }
    .ada-text {
      @include scaled-spacer('margin','bottom','sm');
      color: $brand-primary;
      font-size: $font-size-xs;
      @include media-breakpoint-up(md) {
        @include last-child-zero-margin;
      }
    }
    .nav-partners {
      ul {
        @include list-normalize;
        font-size: $font-size-xs;
        font-weight: $font-weight-bold;
        li {
          margin: .75em 0;
          line-height: 1.1;
          @include media-breakpoint-up(md) {
            text-align: right;
          }
        }
      }
    }
  }
  section.footer-contact {
    @include scaled-spacer('padding','top','xs');
    @include scaled-spacer('padding','bottom','xs');
    @include color-block('green');
    font-size: $font-size-xs;
    &>.container {
      &>.row {
        @include media-breakpoint-down(sm) {
          text-align: center;
          flex-direction: column-reverse;
          .nav-footer {
            @include scaled-spacer('margin','bottom','md');
          }
        }
      }
    }
    .copyright {
      margin: 0;
    }
    .nav-footer {
      @include nav-piped;
      @include media-breakpoint-up(md) {
        ul {
          justify-content: flex-end;
        }
      }
    }
  }
}
