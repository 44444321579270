/* FUNCTIONS
  --------------------------------------- */
@function gutter-scale($size) {
  @return map-get($base-gutter-scales, $size);
}
@function gutter-height($breakpoint) {
  @return map-get($base-gutter-heights, $breakpoint);
}
@function map-deep-get($map, $keys...) {
  @each $key in $keys {
    $map: map-get($map, $key);
  }
  @return $map;
}
@function create_color_map($bg, $text, $overline, $headline, $link, $hover, $border, $icon) {
  $map: (
    bg:       $bg,
    text:     $text,
    overline: $overline,
    headline: $headline,
    link:     $link,
    hover:    $hover,
    border:   $border,
    icon:     $icon
  );
  @return $map;
}


/* HEIGHT SPACERS
  --------------------------------------- */
@mixin scaled-spacer($csstype,$csslocation,$scale) {
  #{$csstype}-#{$csslocation}: gutter-height(xs)*gutter-scale($scale);
  @include media-breakpoint-up(md) { #{$csstype}-#{$csslocation}: gutter-height(md)*gutter-scale($scale); }
  @include media-breakpoint-up(lg) { #{$csstype}-#{$csslocation}: gutter-height(lg)*gutter-scale($scale); }
}
@mixin scaled-spacer-negative($csstype,$csslocation,$scale) {
  #{$csstype}-#{$csslocation}: -(gutter-height(xs)*gutter-scale($scale));
  @include media-breakpoint-up(md) { #{$csstype}-#{$csslocation}: -(gutter-height(md)*gutter-scale($scale)); }
  @include media-breakpoint-up(lg) { #{$csstype}-#{$csslocation}: -(gutter-height(lg)*gutter-scale($scale)); }
}
@mixin scaled-absolute($csslocation,$scale) {
  #{$csslocation}: (gutter-height(xs)*gutter-scale($scale));
  @include media-breakpoint-up(md) { #{$csslocation}: (gutter-height(md)*gutter-scale($scale)); }
  @include media-breakpoint-up(lg) { #{$csslocation}: (gutter-height(lg)*gutter-scale($scale)); }
}
@mixin scaled-absolute-negative($csslocation,$scale) {
  #{$csslocation}: -(gutter-height(xs)*gutter-scale($scale));
  @include media-breakpoint-up(md) { #{$csslocation}: -(gutter-height(md)*gutter-scale($scale)); }
  @include media-breakpoint-up(lg) { #{$csslocation}: -(gutter-height(lg)*gutter-scale($scale)); }
}

@mixin full-padding-spacer($size) {
  @include scaled-spacer('padding','top',$size);
  @include scaled-spacer('padding','right',$size);
  @include scaled-spacer('padding','bottom',$size);
  @include scaled-spacer('padding','left',$size);
}

/* COLORS
  --------------------------------------- */

/*1 name        2 bgcolor     3 text      4 overline       5 headline      6 link color    7 hover color     8 border      9 icon    */
$color-list: (
  white         $white        $body-color $orange-dark     $brand-primary  $link-color     $black            $green        $orange,
  black         $black        $white      $white           $white          $white          $orange           $orange       $orange,
  gray          $gray         $white      $white           $white          $white          $orange           $orange       $orange,
  gray-light    $gray-light   $body-color $orange-dark     $brand-primary  $link-color     $black            $green        $orange,
  blue          $blue         $white      $white           $white          $blue-light     $white            $blue-light   $orange,
  blue-light    $blue-light   $body-color $brand-primary   $brand-primary  $link-color     $link-hover-color $blue         $orange,
  green         $green        $white      $orange          $white          $white          $green-light      $sage         $orange,
  green-light   $green-light  $body-color $brand-primary   $brand-primary  $link-color     $link-hover-color $green        $orange,
  orange        $orange       $white      $white           $white          $white          $body-color       $orange-light $green,
  orange-light  $orange-light $body-color $brand-primary   $brand-primary  $link-color     $black            $orange-dark  $orange,
  sage          $sage         $white      $white           $white          $white          $orange-light     $green        $orange,
  sage-light    $sage-light   $body-color $brand-primary   $brand-primary  $link-color     $link-hover-color $green        $orange,
);

$color-map: ();

@each $value in $color-list {
  .bg-#{nth($value, 1)},
  .has-#{nth($value, 1)}-background-color {
    background-color: #{nth($value, 2)};
    color: #{nth($value, 3)};
    border-color: #{nth($value, 8)};
    .overline {
      color: #{nth($value, 4)};
    }
    h1,h2,h3,h4,h5,h6,.h1,.h2,.h3,.h4,.h5,.h6 {
      color: #{nth($value, 5)};
    }
    .link-line {
      i {
        color: #{nth($value, 9)};
      }
    }
    a {
      color: #{nth($value, 6)};
      @include hover-focus {
        color: #{nth($value, 7)};
      }
      &.readmore {
        &:after {
          color: #{nth($value, 9)};
        }
      }
    }
    .nav-piped ul li {
      border-color: #{nth($value, 6)};
    }
  }
  .btn-social {
    &.social-#{nth($value, 1)} {
      background-color: #{nth($value, 2)};
    }
  }
  $map: ();
  $key: #{nth($value, 1)};
  $map: map-merge($map, ($key: create_color_map(#{nth($value, 2)}, #{nth($value, 3)},#{nth($value, 4)},#{nth($value, 5)},#{nth($value, 6)},#{nth($value, 7)},#{nth($value, 8)},#{nth($value, 9)})) );
  $color-map: map-merge($color-map, $map);
}
@mixin color-block($block-color) {
  background-color: map-deep-get($color-map, $block-color, "bg");
  color: map-deep-get($color-map, $block-color, "text");
  border-color: map-deep-get($color-map, $block-color, "border");
  .overline {
    color: map-deep-get($color-map, $block-color, "overline");
  }
  h1,h2,h3,h4,h5,h6,.h1,.h2,.h3,.h4,.h5,.h6 {
    color: map-deep-get($color-map, $block-color, "headline");
  }
  .link-line {
    i {
      color: map-deep-get($color-map, $block-color, "icon");
    }
  }
  a {
    color: map-deep-get($color-map, $block-color, "link");
    @include hover-focus {
      color: map-deep-get($color-map, $block-color, "hover");
    }
    &.readmore {
      &:after {
        color: map-deep-get($color-map, $block-color, "icon");
      }
    }
  }
  .nav-piped ul li {
    border-color: map-deep-get($color-map, $block-color, "link");
  }
}


/* FONTS
  --------------------------------------- */
@mixin overline {
  font-weight: $font-weight-bold;
  font-size: $font-size-sm;
  text-transform: uppercase;
  line-height: $line-height-base;
  letter-spacing: .1em;
}
@mixin paragraph-spacing {
  margin-bottom: 1.2rem;
}
$heading-sizes: (
  h1: $h1-font-size,
  h2: $h2-font-size,
  h3: $h3-font-size,
  h4: $h4-font-size,
  h5: $h5-font-size,
  h6: $h6-font-size
);
@mixin resize-header($size) {
  font-size: map-get($heading-sizes, $size);
}

/* ELEMENTS
  --------------------------------------- */
@mixin list-normalize {
  margin: 0;
  padding: 0;
  list-style: none;
}
@mixin last-child-zero-margin {
  :last-child {
    margin-bottom: 0;
  }
}
@mixin animate-arrow {
  &:after {
    transform: translateX($arrow-animate-size);
  }
}
@mixin blog-tile {
  margin-bottom: 30px;
  a.blog-link {
    @include last-child-zero-margin;
    @include color-block('white');
    display: block;
    font-size: $font-size-sm;
    border-top: 10px solid $brand-primary;
    padding: 24px;
    height: 100%;
    box-shadow: 0px 0px 2px rgba($black, 0.2);
    color: $body-color;
    text-decoration: none;
    @include media-breakpoint-up(md) {
      margin: 0 10px;
    }
    @include media-breakpoint-up(lg) {
      font-size: $font-size-xs;
    }
    figure,h3 {
      margin-bottom: 24px;
    }
    h3 {
      color: $brand-primary;
    }
    .link-line {
      font-size: $font-size-sm;
      color: $brand-primary;
      i {
        @include transition($link-transition-move);
        margin-left: .4em;
        font-size: $font-size-xs;
      }
    }
    @include hover-focus {
      box-shadow: 0px 0px 2px rgba($brand-primary, 0.6);
      .link-line {
        i {
          transform: translateX($arrow-animate-size);
        }
      }
    }
  }
}


/* NAVS
  --------------------------------------- */
@mixin nav-horizontal {
  position: relative;
  overflow: hidden;
  ul {
    @include list-normalize;
    @extend .d-flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    li {
      flex-basis: auto;
      margin: 0;
      padding: 0 .8em;
      text-align: center;
      line-height: 1;
      &:first-child {
        padding-left: 0;
      }
      &:last-child {
        padding-right: 0;
      }
    }
  }
}
@mixin nav-piped {
  @include nav-horizontal;
  ul {
    margin-left: -1px;
    li {
      border-left-width: 1px;
      border-left-style: solid;
      line-height: 1;
      &:first-child {
        border-left: none;
      }
    }
  }
}


/* COLUMNS
  --------------------------------------- */
@mixin wp-full-width-no-padding {
  width: 100vw;
  max-width: 100vw;
  margin: 0;
  margin-left: calc(50% - 50vw);
}
@mixin main-column-width {
  @include make-col-ready();
  @include make-col(12);
  @include media-breakpoint-up(md) {
    @include make-col(10);
    @include make-col-offset(1);
  }
  @include media-breakpoint-up(lg) {
    @include make-col(8);
    @include make-col-offset(2);
  }
}

