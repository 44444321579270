// WordPress Generated Classes
// http://codex.wordpress.org/CSS#WordPress_Generated_Classes

figure.wp-block-pullquote blockquote, blockquote.wp-block-quote {
  @include scaled-spacer('margin','top','xl');
  @include scaled-spacer('margin','bottom','xl');
  font-size: $font-size-xl;
  padding-left: 2.75em;
  line-height: 1.5;
  color: $brand-primary;
  background: url(../images/quote-green.png) no-repeat 10px 0;
  background-size: 42px auto;
  p {
    font-style: italic;
    font-family: $font-family-serif;
    &:last-child {
      margin-bottom: .75em;
    }
  }
  cite {
    display: block;
    font-size: $font-size-xs;
    font-style: normal;
    font-weight: $font-weight-bold;
    text-transform: uppercase;
  }
}
.wp-block-columns {
  @extend .row;
  .wp-block-column {
    @extend .col;
    margin-bottom: $grid-gutter-width;
  }
}


// Media alignment
.alignnone {
  margin-left: 0;
  margin-right: 0;
  max-width: 100%;
  height: auto;
}
.aligncenter {
  display: block;
  margin: ($spacer / 2) auto;
  height: auto;
}
.alignleft,
.alignright {
  margin-bottom: ($spacer / 2);
  height: auto;
}
@include media-breakpoint-up(sm) {
  // Only float if not on an extra small device
  .alignleft {
    float: left;
    margin-right: ($spacer / 2);
  }
  .alignright {
    float: right;
    margin-left: ($spacer / 2);
  }
}

// Captions
.wp-caption {
  @extend .figure;
}
.wp-caption > img {
  @extend .figure-img;
  @extend .img-fluid;
}
.wp-caption-text {
  @extend .figure-caption;
}
.wp-caption-text {
  @extend .figure-caption;
}

.has-text-align-center {
  text-align: center;
}

// Text meant only for screen readers
.screen-reader-text {
  @extend .sr-only;
  @extend .sr-only-focusable;
}
