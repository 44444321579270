.blog {
  main.main {
    &>.sharedaddy {
      @extend .sr-only;
    }
  }
}
figure.featured-image {
  @include scaled-spacer('margin','bottom','md');
}
.blog-block {
  .blog-row {
    .blog-tile {
      @include blog-tile;
    }
  }
}


/* NAVS
  --------------------------------------- */
nav.posts-navigation {
  @include scaled-spacer('margin','top','lg');
  padding: 0 10px;
  .nav-links {
    @include make-row();
    &>div {
      @include make-col-ready();
      @include make-col(6);
      &.nav-next {
        margin-left: auto;
        text-align: right;
      }
      a {
        @extend .btn;
      }
    }
  }
}
