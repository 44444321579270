a.btn,.btn {
  @include transition($link-transition);
  padding: $btn-padding-y $btn-padding-x;
  cursor: inherit;
  line-height: $btn-line-height !important;
  font: $btn-font-weight $btn-font-size $btn-font-family;
  color: $btn-default-color !important;
  background-color: $btn-default-bg;
  border: none;
  border-radius: $btn-radius;
  @include hover-focus {
    color: $btn-default-color-hover !important;
    background-color: $btn-default-bg-hover;
    border-color: $btn-default-border-hover;
    outline: none;
    box-shadow: $btn-focus-box-shadow;
  }
  &.btn-sm {
    font-size: $btn-font-size-sm;
    padding: $btn-padding-y-sm $btn-padding-x-sm;
  }
}

.btn-social {
  display: block;
  width: $btn-social-width;
  height: $btn-social-height;
  color: $btn-social-color !important;
  font-size: $btn-social-font-size;
  background-color: $btn-social-bg;
  border-radius: 50%;
  line-height: 1;
  position: relative;
  i {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }
  @include hover-focus {
    color: $btn-social-color-hover;
    background-color: $btn-social-bg-hover;
  }
}
