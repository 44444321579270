/* GRID SETTINGS
  --------------------------------------- */
$base-gutter-heights: (
  xs: 10px,
  md: 15px,
  lg: 20px
);
$base-gutter-scales: (
  xs: 1,
  sm: 1.5,
  md: 2,
  lg: 2.5,
  xl: 3
);

$grid-gutter-width-base:  10px;
$grid-gutter-width:       $grid-gutter-width-base;
$grid-gutter-height:      1.8181rem;  // 32.7  /  36.3  /   40

$main-sm-columns:         12;
$sidebar-sm-columns:      4;

$header-height:           80px;
$header-height-sm:        65px;
$adminbar-height:         32px;
$adminbar-height-sm:      46px;

$spacer-fixed:            16px;
$arrow-animate-size:      4px;
$decorative-square-size:  40px;


/* COLORS
  --------------------------------------- */
$green:                 #00643C;
$green-light:           #F1F5ED;
$sage:                  #789D4A;
$sage-light:            $green-light;
$orange:                #ECA154;
$orange-dark:           #CB6015;
$orange-light:          #FDF5EE;
$blue:                  #7BAFD4;
$blue-light:            #F2F7FB;

$white:                 #fff;
$black:                 #000;
$black-near:            #414244;

$gray:                  #97999B;
$gray-dark:             #696A6C;
$gray-light:            #F4F5F5;

$body-color:            $black-near;
$body-bg:               $white;

$brand-primary:         $green;
$brand-secondary:       $orange;

$link-color:            $brand-primary;
$link-decoration:       none;
$link-hover-color:      $black;
$link-hover-decoration: underline;
$link-transition:       all .15s ease-in-out;
$link-transition-move:  transform .15s ease-in-out;

/* FONTS
  --------------------------------------- */
$font-family-sans:      Arial, "Helvetica Neue", Helvetica, sans-serif;
$font-family-serif:     Palatino, "Palatino Linotype", "Palatino LT STD", "Book Antiqua", Georgia, serif;;
$font-family-title:     $font-family-sans;
$font-family-base:      $font-family-sans;

                        // sizes        // xs/sm /   md   /   lg
$font-size-base:        1rem;           //  18   /   20   /   22
$font-size-xs:          .7272rem;       //  13   /  14.5  /   16
$font-size-sm:          .9090rem;       // 16.3  /  18.1  /   20
$font-size-lg:          1.13636rem;     // 20.4  /  22.7  /   25
$font-size-xl:          1.27272rem;     // 22.9  /  25.4  /   28

$font-fixed-base:       16px;
$font-fixed-sm:         14px;

$hero-font-size:        3.6363rem;      // 65.4  /  27.2  /   80
$hero-font-size-alt:    3rem;           //  54   /   60   /   66

$h1-font-size:          3.1818rem;      // 57.2  /  63.6  /   70
$h2-font-size:          2.2727rem;      // 40.9  /  45.4  /   50
$h3-font-size:          1.8181rem;      // 32.7  /  36.3  /   40
$h4-font-size:          $font-size-lg;
$h5-font-size:          $font-size-sm;
$h6-font-size:          $h5-font-size;

$line-height-base:      1.36;

$font-weight-normal:    400;
$font-weight-base:      $font-weight-normal;
$font-weight-bold:      700;

$headings-font-family:  $font-family-title;
$headings-font-weight:  $font-weight-bold;
$headings-line-height:  1.2;

/* BUTTONS
  --------------------------------------- */
$btn-line-height:                1.2;
$btn-font-size:                  16px;
$btn-font-size-sm:               14px;
$btn-font-family:                $font-family-base;
$btn-font-weight:                $font-weight-bold;
$btn-box-shadow:                 none;
$btn-focus-box-shadow:           none;
$btn-active-box-shadow:          none;
$btn-radius:                     6px;

$btn-padding-y:                  12px;
$btn-padding-x:                  16px;
$btn-padding-y-sm:               9px;
$btn-padding-x-sm:               14px;

$btn-default-color:              $white;
$btn-default-bg:                 $brand-primary;
$btn-default-border:             $btn-default-bg;
$btn-default-color-hover:        $btn-default-color;
$btn-default-bg-hover:           $black;
$btn-default-border-hover:       $btn-default-bg-hover;

$btn-social-width:               40px;
$btn-social-height:              40px;
$btn-social-font-size:           $font-size-sm;
$btn-social-color:               $btn-default-color;
$btn-social-bg:                  $btn-default-bg;
$btn-social-color-hover:         $btn-default-color;
$btn-social-bg-hover:            $btn-default-bg-hover;
