.wp-full-width {
  @include wp-full-width-no-padding;
}
.uvm-block {
  @include scaled-spacer('padding','top','sm');
  @include scaled-spacer('padding','bottom','xl');
}
.uvm-block-lg-bg {
  @include scaled-spacer('margin','top','md');
  @include scaled-spacer('margin','bottom','lg');
  @include scaled-spacer('padding','top','xl');
  @include scaled-spacer('padding','bottom','lg');
}
main.main {
  :last-child {
    &.uvm-block-lg-bg {
      @include scaled-spacer-negative('margin','bottom','xl');
    }
  }
}
.banner-block {
  font-size: $font-size-sm;
  .cta-banner {
    margin-bottom: $grid-gutter-width;
    article {
      @include full-padding-spacer(sm);
      border-left-width: $grid-gutter-width;
      border-left-style: solid;
      height: 100%;
      &>.row {
        &.row-nested {
          flex-direction: column-reverse;
        }
        @include media-breakpoint-down(sm) {
          flex-direction: column-reverse;
        }
      }
      @include media-breakpoint-up(md) {
        border-left-width: $grid-gutter-width*1.5;
      }
      @include media-breakpoint-up(lg) {
        border-left-width: $grid-gutter-width*2;
      }
      .details {
        @include media-breakpoint-up(md) {
          @include last-child-zero-margin;
        }
      }
      .media {
        figure {
          width: 100%;
          @include scaled-spacer('margin','bottom','md');
          img {
            max-height: 230px;
          }
        }
        @include media-breakpoint-up(md) {
          @include last-child-zero-margin;
          figure {
            text-align: center;
            img {
              max-height: 275px;
            }
          }
        }
      }
    }
  }
  .nested-banners {
    margin-bottom: $grid-gutter-width;
    article {
      height: 100%;
      &>div {
        @include full-padding-spacer(sm);
        &:last-child {
          @include scaled-spacer('padding','bottom','xs');
        }
        @include media-breakpoint-down(sm) {
          @include scaled-spacer('padding','left','md');
        }
      }
    }
  }
}
.related-articles-block {
  h2 {
    @include scaled-spacer('margin','bottom','lg');
    text-align: center;
  }
  .blog-tile {
    @include blog-tile;
  }
  .viewall {
    margin-top: 30px;
    text-align: center;
    .btn {
      min-width: 180px;
    }
  }
}
.tiles-block {
  font-size: $font-size-sm;
  .cta-tile {
    margin-bottom: $grid-gutter-width;
    article {
      @include full-padding-spacer(sm);
      border-top-width: $grid-gutter-width;
      border-top-style: solid;
      height: 100%;
      @include media-breakpoint-up(md) {
        border-top-width: $grid-gutter-width*1.5;
      }
      @include media-breakpoint-up(lg) {
        border-top-width: $grid-gutter-width*2;
      }
      @include last-child-zero-margin;
      figure {
        @include scaled-spacer('margin','bottom','sm');
        width: 100%;
        img {
          max-height: 150px;
        }
      }
      h3 {
        @include resize-header(h4);
      }
    }
  }
}
.simple-text-block {
  .simple-text-col {
    @include make-col-ready();
    @include main-column-width;
  }
}
.stats-block {
  @include color-block('gray-light');
  .stats-col {
    @include make-col-ready();
    @include make-col(12);
    article {
      @include scaled-spacer('margin','top','lg');
      .overline {
        min-height: $decorative-square-size;
        position: relative;
        padding-left: $decorative-square-size*1.4;
        &:before {
          position: absolute;
          top: 3px;
          left: 0;
          display: block;
          content: "";
          height: $decorative-square-size;
          width: $decorative-square-size;
          background-color: $orange;
          @include media-breakpoint-up(md) {
            top: 5px;
          }
        }

      }
      .stats {
        font-size: $font-size-sm;
        .stat {
          @include scaled-spacer('margin','top','xs');
          @include scaled-spacer('margin','bottom','xs');
          figure {
            margin-bottom: 0;
            img {
              max-height: 50px;
              width: auto;
            }
          }
          em.number {
            font: normal normal $hero-font-size-alt $font-family-serif;
            color: $brand-primary;
            display: block;
          }
          div {
            @include scaled-spacer('padding','right','sm');
          }
        }
      }
    }
  }
}
.file-library-block {
  @include color-block('white');
  &>.container {
    &>.row {
      justify-content: center;
    }
  }
  .library-item {
    @include scaled-spacer('margin','bottom','sm');
    article {
      font-size: $font-size-xs;
      @include full-padding-spacer(lg);
      padding-top: 0 !important;
      &>a {
        display: block;
        text-decoration: none;
        @include hover-focus {
          h3 {
            color: $black;
          }
        }
        figure {
          margin-bottom: .75rem;
        }
        h3 {
          @include transition($link-transition);
          @include resize-header(h5);
        }
      }
      ul.actions {
        @include list-normalize;
        li {
          display: inline-block;
          width: 48%;
          a {
            display: block;
            color: $gray-dark;
            text-decoration: none;
            i {
              @include transition($link-transition);
              color: $orange;
              margin-right: .5em;
            }
            @include hover-focus {
              color: $black;
              i {
                color: $orange-dark;
              }
            }
          }
        }
      }
    }
  }
}
.service-list-group {
  @include scaled-spacer('margin','top','md');
  @include scaled-spacer('margin','bottom','lg');
  .group-title {
    @include overline;
    @include full-padding-spacer(xs);
    @include scaled-spacer('margin','bottom','sm');
    line-height: 1.1;
    border-left-width: $grid-gutter-width;
    border-left-style: solid;
    @include media-breakpoint-up(md) {
      border-left-width: $grid-gutter-width*1.5;
    }
    @include media-breakpoint-up(lg) {
      border-left-width: $grid-gutter-width*2;
    }
  }
  .service-lists {
    column-count: 2;
    article {
      @include scaled-spacer('margin','bottom','sm');
      break-inside: avoid;
      font-size: $font-size-xs;
      h4 {
        @include overline;
        color: $brand-primary;
        line-height: 1.1;
        margin-bottom: .25em;
      }
      ul {
        padding-left: 1.5em;
        margin-bottom: 0;
      }
    }
  }
}
