.hero {
  min-height: 660px;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  position: relative;
  article {
    @include scaled-spacer('padding','top','md');
    @include scaled-spacer('padding','bottom','md');
    @include last-child-zero-margin;
    .headline {
      @extend h1;
      font-size: $hero-font-size;
    }
  }
  &:before {
    content: "";
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    opacity: 0.25;
  }
}
.hero-banner {
  @include media-breakpoint-up(md) {
    min-height: 420px;
  }
  @include media-breakpoint-up(lg) {
    min-height: 420px;
  }
  @include media-breakpoint-up(xl) {
    min-height: 420px;
  }
  &>.container-fluid {
    &>.row {
      @include media-breakpoint-up(md) {
        height: 100%;
        flex-direction: row-reverse;
      }
    }
  }
  .details {
    position: relative;
    article {
      padding: 30px 10px;
      @include media-breakpoint-up(sm) {
        max-width: 530px;
        padding: 0;
        @include scaled-spacer('padding','top','sm');
        @include scaled-spacer('padding','bottom','sm');
        margin: 0 auto;
      }
      @include media-breakpoint-up(md) {
        @include scaled-spacer('padding','top','sm');
        @include scaled-spacer('padding','bottom','sm');
        max-width: 421px; // 58% MD(720) - 5
        padding-right: 0;
        margin-right: inherit;
        position: relative;
        top:50%;
        transform: translateY(-50%);
        margin-left: auto;
      }
      @include media-breakpoint-up(lg) {
        max-width: 521px; // 58% of LG(960) - gutter-pad(5) - 1/2 column width(35)
        margin-right: 35px;
      }
      @include media-breakpoint-up(xl) {
        max-width: 619px; // 58% of  XL(1140) - gutter-pad(5) - 1/2 column width(42)
        margin-right: 42px;
      }
      @include last-child-zero-margin;
      .headline {
        @extend h1;
        line-height: 1.1;
      }
    }
  }
  .media {
    height: 300px;
    @include media-breakpoint-up(md) {
      height: 100%;
    }
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
  }
}
.page-header {
  @include scaled-spacer('margin','bottom','sm');
  h2.page-title {
//     @include resize-header(h1);
    color: $brand-primary;
  }
  .sharedaddy {
    .sd-sharing.sd-social-icon {
      @extend .d-flex;
      align-items: center;
      margin-bottom: 0;
      .sd-title {
        @include overline;
        line-height: 1;
        margin: 0 $grid-gutter-width 0 0;
        color: $orange-dark;
        &:before {
          display: none;
        }
      }
      ul {
        li {
          margin: 0 8px 0 0;
/*
          a.sd-button {
            @include hover-focus {
              opacity: 1;
              background-color: $black !important;
            }
          }
          &.share-facebook {
            a.sd-button {
              background-color: $green;
            }
          }
          &.share-linkedin {
            a.sd-button {
              background-color: $sage;
            }
          }
          &.share-twitter {
            a.sd-button {
              background-color: $blue;
            }
          }
*/
        }
      }
      .share-customize-link {
        display: none !important;
      }
    }
  }
}


