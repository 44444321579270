header.banner {
  .branding {
    @include scaled-spacer('padding','top','sm');
    @include scaled-spacer('padding','bottom','sm');
    .brand {
      display: block;
      width: 100%;
      max-width: 284px;
    }
    .navbar-toggler {
      float: right;
      width: $grid-gutter-width*5;
      text-align: center;
      i {
        &.fas {
          display: none;
        }
        &.fa-times {
          display: inline-block;
        }
      }
      &.collapsed {
        i {
          &.fas {
            display: inline-block;
          }
          &.fa-times {
            display: none;
          }
        }
      }
    }
    .nav-phone {
      ul {
        justify-content: flex-end;
        font-size: $font-size-xs;
      }
    }
    .nav-cta-primary {
      text-align: right;
      margin-top: $grid-gutter-width*1.5;;
    }
  }
  section.nav-block {
    @include color-block('orange-light');
    @include media-breakpoint-up(md) {
      display: block !important;
      padding-top: $grid-gutter-width;
      padding-bottom: $grid-gutter-width;
    }
    .nav-phone {
      padding-top: $grid-gutter-width*1.5;
      ul {
        justify-content: center;
        font-size: $font-fixed-base;
      }
    }
    .nav-cta-primary {
      text-align: center;
      margin-top: $grid-gutter-width;
    }
    nav.nav-primary {
      font-weight: $font-weight-bold;
      @include media-breakpoint-down(sm) {
        padding-bottom: $grid-gutter-width*1.5;
      }
      @include media-breakpoint-only(md) {
        font-size: $font-fixed-sm;
      }
      @include media-breakpoint-up(lg) {
        font-size: $font-fixed-base;
      }
      ul.nav {
        @include media-breakpoint-down(sm) {
          flex-direction: column;
          li {
            a {
              display: block;
              padding: $grid-gutter-width/2 0;
            }
          }
        }
        @include media-breakpoint-up(md) {
          justify-content: space-evenly;
        }
        li.active {
          &>a {
            color: $black;
            text-decoration: none;
          }
        }
      }
    }
  }
}
